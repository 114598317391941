import React, { useEffect, useState } from "react";
// import coursesList from '../../CoursesList'
import { useLocation } from "react-router-dom";
import "./Topic.css";
import { Box, Button, Tab, TabList, Tabs, Input, Textarea, Modal, ModalDialog, ModalClose, Typography } from "@mui/joy";
import "../../Common/Common.css";

import { fetchBlobsThunk } from "../../Store/actions/blobStorageAction";
import { useDispatch, useSelector } from "react-redux";
import { getTasksByNodeIdThunk } from "../../Store/actions/taskAction";
import { fetchCourseDetailsThunk, updateTopicThunk } from "../../Store/actions/courseAction";
import { getTopicInfoFromNode } from "../../helpers/node";
import { addManualVideoThunk, fetchVideosByIdsThunk } from "../../Store/actions/videoAction";
import Task from "./topic-components/task";
import Video from "./topic-components/video";
import References from "./topic-components/references";
import WithAuth from "../utils/WithAuth";
import { showToast } from "../../Store/slices/commonSlice";
import { ToastType } from "../../Constants/toast-type";
import { addVideo, clearVideo } from "../../Store/slices/videoSlice";
import { InfoRounded } from "@mui/icons-material";
import { fetchMaterialByNodeThunk } from "../../Store/actions/materialAction";
import { updateNodeData } from "../../Store/slices/courseSlice";

const Topic = () => {
	const location = useLocation();

	const [activeTab, setActiveTab] = useState("details");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [videoUrl, setVideoUrl] = useState("");
	const [videoTitle, setVideoTitle] = useState("");
	const [videoPoints, setVideoPoints] = useState(20);
	const [loading, setLoading] = useState(false);
	const [duration, setDuration] = useState();
	const [moduleName, setModuleName] = useState("");
	const [moduleDescription, setModuleDescription] = useState("");

	const accountName = process.env.REACT_APP_ACCOUNT_NAME;
	const sasToken = process.env.REACT_APP_SAS_TOKEN;
	const containerName = process.env.REACT_APP_CONTAINER_NAME;

	const dispatch = useDispatch();

	const { taskByNodeId } = useSelector((state) => state.task);
	const { referenceData } = useSelector((state) => state.references);
	const [moduleInfo, setModuleInfo] = useState({});
	const { videoData } = useSelector((state) => state.video);
	const topicId = location.pathname.split("/")[4];
	const courseId = location.pathname.split("/")[2];

	const fetchModuleInfo = async () => {
		const data = await dispatch(fetchCourseDetailsThunk({ courseId }));
		if (data.payload.data) {
			const moduleData = getTopicInfoFromNode(data.payload.data, topicId);
			setModuleInfo(moduleData);
			setModuleName(moduleData?.node_name);
			setModuleDescription(moduleData?.description);
			if (moduleData?.videos && moduleData?.videos.length > 0) {
				dispatch(fetchVideosByIdsThunk(moduleData.videos));
			} else {
				dispatch(clearVideo());
			}
		}
	};

	useEffect(() => {
		fetchModuleInfo();
		dispatch(getTasksByNodeIdThunk(topicId));
		dispatch(fetchMaterialByNodeThunk(topicId));
	}, [topicId]);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	// const fetchBlobs = () => {
	// 	if (!accountName || !sasToken || !containerName) {
	// 		alert("Please make sure you have set the Azure Storage credentials in the .env file");
	// 		return;
	// 	}
	// 	dispatch(fetchBlobsThunk());
	// };

	const handleAddVideo = async () => {
		if (!videoUrl || !videoTitle || !duration) {
			dispatch(showToast({ message: "Please fill all the fields", type: ToastType.ERROR }));
			return;
		}

		// need to give duration in time posgres format without timezone

		const video = {
			title: videoTitle,
			url: videoUrl,
			points: videoPoints,
			duration,
			node_id: topicId,
		};
		setLoading(true);
		const response = await dispatch(addManualVideoThunk(video));
		if (response?.payload?.data) {
			dispatch(addVideo(response.payload.data));
			setLoading(false);
			setVideoTitle("");
			setVideoUrl("");
			setDuration();
			setVideoPoints(20);
			setIsModalOpen(false);
		}
	};

	const renderDetails = () => {
		const handleEditTopic = async () => {
			if (!moduleInfo?.node_name || (moduleName === moduleInfo?.node_name && moduleDescription === moduleInfo?.description)) return;

			const topicData = {
				node_id: topicId,
				node_name: moduleName,
				description: moduleDescription,
			};

			const response = await dispatch(updateTopicThunk(topicData));
			if (response.payload.data) {
				dispatch(updateNodeData(response.payload.data));
				setModuleInfo(response.payload.data);
				dispatch(showToast({ toastMessage: "Topic updated successfully", toastType: ToastType.SUCCESS }));
			}
		};

		return (
			<Box sx={{ my: 10 }}>
				<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Name</Typography>
				<Input
					placeholder='e.g. Compilers & Assemblers'
					label='Topic Name'
					sx={{ mb: 2 }}
					value={moduleName}
					slotProps={{
						input: {
							maxLength: 64,
						},
					}}
					onBlur={handleEditTopic}
					onChange={(e) => setModuleName(e.target.value)}
				/>
				<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Topic Description</Typography>
				<Textarea
					minRows={3}
					placeholder='Describe in a few lines what the topic is about.'
					label='Topic Description'
					value={moduleDescription}
					slotProps={{
						textarea: {
							maxLength: 128,
						},
					}}
					onChange={(e) => setModuleDescription(e.target.value)}
					onBlur={handleEditTopic}
				/>
			</Box>
		);
	};

	const renderVideoModal = () => {
		return (
			<Modal
				open={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
					setVideoTitle("");
					setVideoUrl("");
					setDuration();
					setVideoPoints(20);
				}}
				sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}
			>
				<ModalDialog sx={{ width: "70%" }}>
					<ModalClose />
					<Typography level='h5'>Add New Video</Typography>
					<Typography sx={{ mt: 2 }}>Add a video to this topic</Typography>
					<Input
						placeholder='e.g. Compiler - Assembler Differences'
						label='Video Title'
						vaqlue={videoTitle}
						slotProps={{
							input: {
								maxLength: 64,
							},
						}}
						onChange={(e) => setVideoTitle(e.target.value)}
					/>
					<Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
						<Typography sx={{}}>Paste the URL of the video</Typography>
						<Box
							sx={{
								padding: "2px 8px",
								border: "1px solid",
								borderColor: "divider",
								borderRadius: "8px",
								width: "fit-content",
								backgroundColor: "#1976d2", // A more vibrant blue background
								display: "flex",
								alignItems: "center",
								mx: 2,
							}}
						>
							<InfoRounded sx={{ color: "#fff", mr: 1, fontSize: "1rem" }} /> {/* White icon with some margin */}
							<Typography sx={{ fontSize: ".85rem", color: "#fff" }}>
								{" "}
								{/* White text */}
								Please enter a valid and publicly accessible video URL
							</Typography>
						</Box>
					</Box>
					<Input
						placeholder='e.g. https://vimeo.com/123456789'
						label='Video URL'
						value={videoUrl}
						slotProps={{
							input: {
								maxLength: 50,
							},
						}}
						sx={{ mb: 2 }}
						onChange={(e) => setVideoUrl(e.target.value)}
					/>
					<Box>
						<Typography
							sx={{
								mb: 1,
							}}
						>
							Duration
						</Typography>
						<Input
							placeholder='e.g. 00:30:00'
							label='Duration'
							value={duration}
							type='time'
							slotProps={{
								input: {
									step: 1,
								},
							}}
							onChange={(e) => setDuration(e.target.value)}
						/>
					</Box>
					<Box sx={{ mt: 2 }}>
						<Typography>Points</Typography>
						<Box sx={{ display: "flex", gap: 1 }}>
							{[1, 5, 10, 15, 20, 25].map((point) => (
								<Button
									key={point}
									variant='outlined'
									color='neutral'
									onClick={() => setVideoPoints(point)}
									sx={{
										bgcolor: `${videoPoints === point ? "#000" : "white"}`,
										color: `${videoPoints === point ? "white" : "black"}`,
										"&:hover": {
											bgcolor: `${videoPoints === point ? "#000" : "#f4f4f4"}`, // Change "gray" to your desired hover color
											color: `${videoPoints === point ? "white" : "black"}`,
										},
									}}
								>
									{point}
								</Button>
							))}
						</Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
						<Button
							variant='outlined'
							color='neutral'
							sx={{ mr: 1 }}
							onClick={() => setIsModalOpen(false)}
						>
							Cancel
						</Button>
						<Button
							onClick={handleAddVideo}
							loading={loading}
							disabled={loading || !videoUrl || !videoTitle || !duration}
						>
							Add Video
						</Button>
					</Box>
				</ModalDialog>
			</Modal>
		);
	};

	const renderTabsInfo = (activeTab) => {
		switch (activeTab) {
			case "details":
				return renderDetails();
			case "videos":
				return <Video setIsModalOpen={setIsModalOpen} />;

			case "tasks":
				return <Task />;

			case "references":
				return <References />;
			default:
				return renderDetails();
		}
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 1000, margin: "auto", p: 2 }}>
			<Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
				<Typography level='h6'>{moduleInfo?.node_name}</Typography>
			</Box>

			<Tabs
				value={activeTab}
				onChange={handleTabChange}
				sx={{
					borderRadius: "8px",
				}}
			>
				<TabList>
					<Tab
						value='details'
						// disabled={isFromAI}
					>
						Topic Details
					</Tab>
					<Tab
						value='videos'
						// disabled={isFromAI}
					>
						Videos {videoData && videoData.length}
					</Tab>
					<Tab value='tasks'>Tasks {(taskByNodeId?.data && taskByNodeId?.data?.length) || 0}</Tab>
					<Tab value='references'>References {referenceData && referenceData?.length}</Tab>
				</TabList>
			</Tabs>

			<Box
				sx={{
					p: 2,
					border: "1px solid",
					borderColor: "divider",
					maxHeight: "25rem",
					borderRadius: "sm",
					background: "white",
				}}
			>
				{renderTabsInfo(activeTab)}
			</Box>

			<Box sx={{ width: "100vw" }}>{renderVideoModal()}</Box>
		</Box>
	);
};

export default WithAuth(Topic);
