import React, { useEffect, useState } from "react";
import CsvFileUpload from "../../../utils/CsvFileUpload";
import { Box, Button, IconButton, Input, Option, Typography, Select } from "@mui/joy";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { addStudentsAndAssignToBatchThunk, fetchStudentsByBatchIdThunk } from "../../../../Store/actions/batchAction";
import { addStudentsAndAssignToBatch } from "../../../../Store/services/batchApiService";
import { setFormFilled, setUnsavedChanges } from "../../../../Store/slices/batchSlice";
import { batchType } from "../../../../Constants/batch-tabs";
import { bgColorObj, colorObj } from "../../../../helpers/styleConfig";
import Loader from "../../../utils/Loader";
import useDebounce from "../../../../hooks/use-debounce";
import PaginationComponent from "../../../utils/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { getFirstLetterCapitalized } from "../../../../helpers/text-formatter";
import axios from "axios";
import { API_URL } from "../../../../Config";

const VerifyStudents = ({ batchId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [sortField, setSortField] = useState("created_at");
	const [filter, setFilter] = useState([]);
	const [inviteLoader, setInviteLoader] = useState(false);

	const debouncedValue = useDebounce(search, 500);

	const { batchDetails, students, loading } = useSelector((state) => state.batch);
	const dispatch = useDispatch();

	const csvUploadClick = async (data) => {
		setUploading(true);
		const response = await addStudentsAndAssignToBatch(data, batchDetails?.data?.batch_id);
		if (response?.success) {
			if (batchId) {
				dispatch(fetchStudentsByBatchIdThunk({ batchId }));
				setUploading(false);
				setIsOpen(false);
			} else {
				dispatch(fetchStudentsByBatchIdThunk({ batchId: batchDetails?.data?.batch_id }));
				dispatch(setFormFilled({ tab: batchType.verifyStudents, filled: true }));
				setUploading(false);
				setIsOpen(false);
			}
			dispatch(setUnsavedChanges(batchDetails?.data?.batch_id));
		}
	};

	useEffect(() => {
		if (debouncedValue) {
			if (currentPage !== 1) setCurrentPage(1);
			else {
				dispatch(
					fetchStudentsByBatchIdThunk({
						batchId: batchId || batchDetails?.data?.batch_id,
						query: {
							search: debouncedValue,
							page: currentPage,
							sortBy: sortField,
							sortOrder,
						},
					})
				);
			}
		} else {
			setCurrentPage(1);
			dispatch(
				fetchStudentsByBatchIdThunk({
					batchId: batchId || batchDetails?.data?.batch_id,
					query: {
						page: currentPage,
						sortBy: sortField,
						sortOrder,
					},
				})
			);
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (batchId || batchDetails?.data?.batch_id) {
			if (debouncedValue) {
				dispatch(
					fetchStudentsByBatchIdThunk({
						batchId: batchId || batchDetails?.data?.batch_id,
						query: {
							search: debouncedValue,
							page: currentPage,
							sortBy: sortField,
							sortOrder,
						},
					})
				);
			} else {
				dispatch(
					fetchStudentsByBatchIdThunk({
						batchId: batchId || batchDetails?.data?.batch_id,
						query: {
							page: currentPage,
							sortBy: sortField,
							sortOrder,
						},
					})
				);
			}
		}
	}, [currentPage, sortField, sortOrder]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleSearchChange = (e) => {
		const value = e.target.value.trim(); // Trim the value here

		// Only set search if the value is non-empty or if it's completely cleared
		if (value !== "" || e.target.value === "") {
			setSearch(e.target.value);
		}
	};

	const handleSortByChange = (e, newValue) => {
		setSortField(newValue);
	};

	const handleSortOrderChange = (e, newValue) => {
		setSortOrder(newValue);
	};

	const stasuses = {
		PENDING: "To be invited",
		COMPLETED: "Invited",
	};

	const renderTableBody = () => {
		if (loading) {
			return (
				<TableRow>
					<TableCell
						colSpan={3}
						sx={{ textAlign: "center" }}
					>
						<Loader />
					</TableCell>
				</TableRow>
			);
		}
		if (students?.data?.length === 0) {
			return (
				<TableRow>
					<TableCell
						colSpan={3}
						sx={{ textAlign: "center" }}
					>
						No students found
					</TableCell>
				</TableRow>
			);
		}
		return students?.data?.data?.map((student) => {
			dispatch(setFormFilled({ tab: batchType.verifyStudents, filled: true }));
			if (student?.student === null) return;
			return (
				<TableRow
					key={student.student_id}
					sx={{}}
				>
					<TableCell sx={{ px: 3, py: 1, fontSize: "1rem" }}>{getFirstLetterCapitalized(student?.student?.name)}</TableCell>
					<TableCell sx={{ px: 3, py: 1, fontSize: "1rem" }}>
						{student?.student?.mobile_number || (
							<Typography
								level='body-xs'
								sx={{ ml: 1 }}
							>
								No number
							</Typography>
						)}
					</TableCell>
					<TableCell sx={{ px: 3, py: 1, fontSize: "1rem" }}>{student?.student?.email}</TableCell>
					<TableCell sx={{ px: 3, py: 1, fontSize: "1rem" }}>
						<Typography
							sx={{
								borderRadius: "5px",
								fontWeight: 500,
								color: colorObj[student?.invite_status],
								backgroundColor: bgColorObj[student?.invite_status],
								textAlign: "center",
								width: "fit-content",
								px: 2,
							}}
						>
							{stasuses[student?.invite_status]}
						</Typography>
					</TableCell>
				</TableRow>
			);
		});
	};

	const renderPagination = () => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: "1rem",
				}}
			>
				<Box
					sx={{
						display: "flex",
						bgcolor: "white",
						justifyContent: "center",
						alignItems: "center",
						padding: " .5rem",
						borderRadius: "8px",
						gap: 5,
					}}
				>
					<PaginationComponent
						totalPage={students?.data?.totalPages}
						onPageChange={handlePageChange}
						currentPage={currentPage}
					/>
				</Box>
			</Box>
		);
	};

	const handleSendInvite = async () => {
		try {
			setInviteLoader(true);
			const batch_id = batchId || batchDetails?.data?.batch_id;
			const response = await axios.post(`${API_URL}/student/send-invite`, { batch_id });
			if (response?.data?.success) {
				dispatch(fetchStudentsByBatchIdThunk({ batchId: batch_id }));
			}
			setInviteLoader(false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Box sx={{ display: "grid" }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography level='h5'>Students</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}
				>
					{students?.data?.data?.length > 0 && (
						<Button
							variant='outlined'
							sx={{ bgcolor: "white", color: "#3838f1", border: "1px solid #3838f1" }}
							onClick={handleSendInvite}
							loading={inviteLoader}
						>
							Send Invites
						</Button>
					)}
					<Button
						onClick={() => setIsOpen(true)}
						variant='primary'
						sx={{ display: "flex", justifySelf: "flex-end", alignItems: "center", gap: 1, backgroundColor: "#3838F1" }}
					>
						<Typography sx={{ width: "fit-content", color: "white" }}>Upload Spreadsheet</Typography>
					</Button>
				</Box>
			</Box>
			<Box sx={{ borderBottom: "1px solid black", my: 1 }}></Box>
			<CsvFileUpload
				openUploadPopup={isOpen}
				closePopup={() => setIsOpen(false)}
				uploadClick={csvUploadClick}
				uploading={uploading}
				setUploading={setUploading}
			/>
			<Box sx={{ display: "flex", my: 1, alignItems: "center", justifyContent: "space-between", gap: "1rem" }}>
				{/* search bar */}
				<Box sx={{ padding: "0 .5rem", display: "flex", alignItems: "center", border: "1px solid #d7d7d7", bgcolor: "white", borderRadius: "10px", width: "70%" }}>
					<SearchIcon
						sx={{
							fontWeight: "200",
						}}
					/>
					<Input
						placeholder='Search Students by name or email '
						value={search}
						sx={{
							width: "100%",
							border: "none",
							outline: "none",
							bgcolor: "white",
							"&::before": {
								display: "none",
							},
							"&:focus-within": {
								outline: "none",
								outlineOffset: "none",
							},
						}}
						onChange={handleSearchChange}
					/>
				</Box>
				{/* sort bar */}
				<Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
					<Box>
						<Select
							placeholder='Sort By'
							sx={{ bgcolor: "white" }}
							onChange={handleSortByChange}
						>
							<Option value='name'>Name</Option>
							<Option value='created_at'>Created at</Option>
						</Select>
					</Box>
					<Box>
						<Select
							placeholder='Sort Order'
							onChange={handleSortOrderChange}
							sx={{ bgcolor: "white" }}
						>
							<Option value='asc'>Ascending</Option>
							<Option value='desc'>Descending</Option>
						</Select>
					</Box>
				</Box>
			</Box>
			<Box sx={{ mt: 2 }}>
				<TableContainer
					component={Paper}
					sx={{ maxHeight: 350, overflow: "auto" }}
				>
					<Table
						stickyHeader
						aria-label='sticky table'
					>
						{/* <TableHead>
							<TableRow>
								<TableCell sx={{ px: 3, py: 1 }}>Name</TableCell>
								<TableCell sx={{ px: 3, py: 1 }}>Phone Number</TableCell>
								<TableCell sx={{ px: 3, py: 1 }}>Email</TableCell>
								<TableCell sx={{ px: 3, py: 1 }}>Status</TableCell>
							</TableRow>
						</TableHead> */}
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</TableContainer>
				{renderPagination()}
			</Box>
		</Box>
	);
};

export default VerifyStudents;
